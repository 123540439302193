import { Box, Button, Chip, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Delete } from '@mui/icons-material'

const PlansReal = () => {
    const CON = React.useContext(GlobalContext)
    const [state, setState] = React.useState({})
    const [plans, setPlans] = React.useState([])

    async function getPlan() {
        const res = await CON.hitAxios({
            path: "/api/admin/get_plans",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setPlans(res.data.data)
        }
    }

    async function delPlan(id) {
        if (window.confirm("Are you sure?")) {
            const res = await CON.hitAxios({
                path: "/api/admin/del_plan",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getPlan()
            }
        }
    }

    React.useEffect(() => {
        getPlan()
    }, [])

    async function addPlan() {
        const res = await CON.hitAxios({
            path: "/api/admin/add_plan",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getPlan()
            setState({})
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>

                <Stack direction={'row'} spacing={1}>
                    <TextField
                        onChange={(e) => setState({ ...state, title: e.target.value })}
                        value={state.title}
                        label="Title" fullWidth size='small' InputProps={CON.inputProps} />

                    <TextField
                        onChange={(e) => setState({ ...state, amount: e.target.value })}
                        value={state.amount}
                        type='number'
                        label="Amount Strike" fullWidth size='small' InputProps={CON.inputProps} />

                    <TextField
                        onChange={(e) => setState({ ...state, amount_discount: e.target.value })}
                        type='number'
                        value={state.amount_discount}
                        label="Amount Real" fullWidth size='small' InputProps={CON.inputProps} />

                    <TextField
                        onChange={(e) => setState({ ...state, validity_days: e.target.value })}
                        type='number'
                        value={state.validity_days}
                        placeholder="Type number of days"
                        label="Validity" fullWidth size='small' InputProps={CON.inputProps} />

                </Stack>

                <TextField
                    onChange={(e) => setState({ ...state, description: e.target.value })}
                    multiline
                    value={state.description}
                    rows={2}
                    label="Small Description" fullWidth size='small' InputProps={CON.inputProps} />

                <Button
                    onClick={addPlan}
                    sx={{
                        background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                        textTransform: 'none', borderRadius: 2,
                        color: 'white'
                    }}
                    variant='contained' >
                    Add Plan
                </Button>
            </Stack>

            <Box mt={4}>

                <Stack direction={'column'} spacing={2}>
                    {plans.map((i, key) => {
                        return (
                            <Box key={key} bgcolor={'action.hover'} p={2} borderRadius={3}>
                                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Stack direction={'column'}>
                                        <Typography>{i.title}</Typography>
                                        <Typography color={'gray'} variant='caption' >{i.validity_days} Days</Typography>
                                    </Stack>

                                    <Stack alignItems={'flex-end'} direction={'column'} spacing={1}>
                                        <Stack direction={'row'} spacing={1}>
                                            <Typography variant='body2' component={'strike'} >${i.amount}</Typography>
                                            <Typography variant='h5' >${i.amount_discount}</Typography>
                                        </Stack>

                                        <Chip
                                            onClick={() => delPlan(i.id)}
                                            color='error' icon={<Delete />} label="Delete" size='small' />
                                    </Stack>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>

            </Box>
        </div>
    )
}

export default PlansReal