import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Delete, ExpandMore } from '@mui/icons-material'

const Faq = () => {
    const [state, setState] = React.useState({})
    const [faq, setFaq] = React.useState([])
    const inputProps = { style: { borderRadius: 10 } }
    const { hitAxios } = React.useContext(GlobalContext)

    async function getFaq() {
        const res = await hitAxios({
            path: "/api/admin/get_faq",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            setFaq(res.data.data)
        }
    }

    React.useEffect(() => {
        getFaq()
    }, [])

    async function addFaq() {
        const res = await hitAxios({
            path: "/api/admin/faq_add",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            setState({ ...state, que: "", ans: "" })
            getFaq()
        }
    }

    async function delFaq(id) {
        if (window.confirm("Are you sure?")) {
            const res = await hitAxios({
                path: "/api/admin/del_faq",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getFaq()
            }
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextField
                    size='small'
                    InputProps={inputProps}
                    value={state.que}
                    onChange={(e) => setState({ ...state, que: e.target.value })}
                    label="Question" fullWidth />

                <TextField
                    size='small'
                    rows={2}
                    multiline
                    InputProps={inputProps}
                    value={state.ans}
                    onChange={(e) => setState({ ...state, ans: e.target.value })}
                    label="Answer" fullWidth />

                <Button
                    onClick={addFaq}
                    sx={{
                        background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                        textTransform: 'none', borderRadius: 2,
                        color: 'white'
                    }}
                    variant='contained' >
                    Add Faq
                </Button>
            </Stack>

            <Box mt={4}>
                <Stack direction={'column'} spacing={2}>
                    {faq?.map((i, key) => {
                        return (
                            <Accordion
                                sx={{ bgcolor: "action.hover" }}
                                key={key}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>{i.que}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant='body2' >{i.ans}</Typography>
                                    <Stack direction={'row'} justifyContent={'flex-end'}>
                                        <IconButton onClick={() => delFaq(i.id)} color='error' >
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Stack>
            </Box>
        </div>
    )
}

export default Faq