import React from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from './context/GlobalContext';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Typography } from '@mui/material';

const CheckOutStripe = () => {
    const location = useLocation();
    const queryParams = {};
    const { hitAxios } = React.useContext(GlobalContext);

    // Parse query parameters
    React.useEffect(() => {
        const parseQueryParams = () => {
            const searchParams = new URLSearchParams(location.search);
            for (let [key, value] of searchParams) {
                queryParams[key] = value;
            }
        };
        parseQueryParams();
    }, [location.search]);

    // Checkout function
    const checkOut = async (id, uid, key) => {
        try {
            console.log({ id, uid, key })
            const stripe = await loadStripe(key);
            const res = await hitAxios({
                path: `/api/user/create_stripe_session?planId=${id}&uid=${uid}`,
                post: false,
                admin: false,
                token_user: ""
            });

            if (res.data.success) {
                const result = await stripe.redirectToCheckout({
                    sessionId: res.data.session.id
                });

                console.log({ result });
            }
        } catch (err) {
            console.log(err)
        }
    };

    // Call checkout function on component mount
    React.useEffect(() => {
        if (queryParams) {
            checkOut(queryParams.plan, queryParams.uid, queryParams.key);
        }
    }, [queryParams, checkOut]);

    return (
        <div>
            <Typography>checking...</Typography>
        </div>
    );
};

export default CheckOutStripe;
