import React from 'react'
import { Switch } from 'react-router-dom'
import { Alert, Snackbar, Box, LinearProgress } from '@mui/material'
import { GlobalContext } from './context/GlobalContext'
import PublicRoute from './routing/PublicRoute'
import AdminRoute from './routing/AdminRoute'
import AdminLogin from './admin/login/AdminLogin'
import Dashboard from './admin/dashboard/Dashboard'
import PasswordRecoveryUser from './PasswordRecoveryUser'
import CheckoutStripe from './CheckoutStripe'

const HomeMainPage = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    return (
        <Box sx={{ position: 'relative' }} color='text.primary' minHeight={'100vh'} bgcolor='#000327'>
            <div style={{ position: "absolute", top: 0, left: 0, right: 0, display: GLOBAL_CONTEXT.data.loading ? 'block' : 'none' }} >
                <LinearProgress
                    color='inherit'
                // sx={{
                //     background: "linear-gradient(to right, #ad5389, #3c1053)",
                //     fontSize: "50px"
                // }}
                />
            </div>


            <Snackbar open={GLOBAL_CONTEXT.data.snack} autoHideDuration={4000} >
                <Alert severity={GLOBAL_CONTEXT.data.snack_success ? 'success' : 'error'}>{GLOBAL_CONTEXT.data.snack_msg}</Alert>
            </Snackbar>

            <div style={{
                opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
                pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial"
            }}>
                <Switch>
                    <PublicRoute path='/login' exact component={AdminLogin} />
                    <AdminRoute path='/admin' exact component={Dashboard} />

                    <PublicRoute path='/checkout-stripe' exact component={CheckoutStripe} />

                    <PublicRoute exact path="/recovery-user/:id" component={PasswordRecoveryUser} />
                </Switch>
            </div>
        </Box>
    )
}

export default HomeMainPage

