import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Button, Stack, TextField } from '@mui/material'

const PaymentGateway = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [data, setData] = React.useState("")

    async function getPay() {
        const res = await hitAxios({
            path: "/api/admin/get_payment_gateway",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setData(res.data.data)
        } else {
            setData({})
        }
    }

    async function updateGateway() {
        const res = await hitAxios({
            path: "/api/admin/update_payment_gateway",
            post: true,
            admin: true,
            obj: data
        })
    }

    React.useEffect(() => {
        getPay()
    }, [])
    return (
        data &&
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextField
                    value={data.stripe_id}
                    onChange={(e) => setData({ ...data, stripe_id: e.target.value })}
                    size='small' label="Stripe ID" fullWidth InputProps={{
                        style: { borderRadius: 10 }
                    }} />

                <TextField
                    value={data.stripe_key}
                    onChange={(e) => setData({ ...data, stripe_key: e.target.value })}
                    size='small' label="Stripe Key" fullWidth InputProps={{
                        style: { borderRadius: 10 }
                    }} />

                <Button
                    sx={{
                        background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                        textTransform: 'none', borderRadius: 2,
                        color: 'white'
                    }}
                    onClick={updateGateway}
                    variant='contained' >
                    Save
                </Button>
            </Stack>
        </div>
    )
}

export default PaymentGateway